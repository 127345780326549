<template>
  <div v-if="pausedSubscription" class="resume-banner">
    <div
      class="container d-flex justify-content-between align-items-center flex-wrap"
    >
      <div class="resume-banner__message d-flex">
        <BookIcon width="24px" height="24px" stroke="white" />

        <p class="mb-24 mb-md-0">
          {{ $label('account.membership.resumeBannerText', { resumeDate }) }}
        </p>
      </div>
      <div class="resume-banner__actions d-flex align-items-center">
        <BaseButton
          class="resume-banner__btn"
          variant="call-to-action"
          @click="showModal"
        >
          {{ $label('account.membership.resumeBannerBtn') }}
        </BaseButton>
      </div>
    </div>
  </div>
  <ResumeSubscriptionModal
    :paused-subscription="pausedSubscription"
    :resume-subscription="resumeSubscription"
    :logged-in-user="loggedInUser"
    modal-id="resumeSubscriptionBanner"
    :hide-modal="hideModal"
  />
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ResumeSubscriptionModal from '~/components/membership/ResumeSubscriptionModal'
import dateFormatter from '~/utils/dateFormatter'
import BookIcon from '~/assets/icons/book-open.svg'
import { useModal } from '~/composables/useModal'

export default {
  components: { ResumeSubscriptionModal, BookIcon },
  setup() {
    const modal = useModal('resumeSubscriptionBanner')

    return { showModal: modal.show, hideModal: modal.hide }
  },
  computed: {
    ...mapGetters({
      loggedInUser: 'loggedInUser',
    }),
    pausedSubscription() {
      return this.loggedInUser && this.loggedInUser.pauseSubscription
    },
    resumeDate() {
      return dateFormatter(this.pausedSubscription.newAutoRenewalDate)
    },
  },
  methods: {
    ...mapActions({
      resumeSubscription: 'account/resumeSubscription',
    }),
  },
}
</script>

<style lang="scss" scoped>
.resume-banner {
  background-color: $cornflower-dark;
}

.container {
  padding: 22px 16px 22px 19px;
}

.resume-banner__message {
  align-items: center;
}

a,
p {
  color: $white;
}

p {
  margin-left: 11px;
}

@include media-breakpoint-down(md) {
  .container {
    padding: 16px 16px 16px 19px;
  }

  .resume-banner__message,
  .resume-banner__actions {
    width: 100%;
    justify-content: center;
  }

  .resume-banner__message {
    align-items: flex-start;
  }

  svg {
    margin-top: 4px;
  }
}

@include media-breakpoint-down(sm) {
  .resume-banner__btn {
    width: 100%;
  }
}
</style>
